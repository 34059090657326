<template>
  <v-main>
    <loading :show="loading" />
    <live-voice :room-id="roomInfo.id" ref="liveVoice" />
    <panorama :show="!loading" :room-id="roomInfo.id" :xml="roomInfo.xml" class="live-panorama" />
    <div class="live-skin" v-show="!loading">
      <v-chip
        class="ma-2 live-avatar"
        color="rgba(0,0,0,0.5)"
        text-color="white"
      >
        <v-avatar left>
          <v-icon>mdi-account-circle</v-icon>
        </v-avatar>
        {{ roomInfo.createUserName }}
      </v-chip>

      <v-speed-dial
        fixed
        v-model="fab"
        bottom
        right
        :direction="'top'"
        :transition="'slide-y-reverse-transition'"
      >
        <template v-slot:activator>
          <v-btn
            v-model="fab"
            color="blue"
            fab
            dark
            small
          >
            <v-icon v-if="fab">
              mdi-close
            </v-icon>
            <v-icon v-else>
              mdi-menu
            </v-icon>
          </v-btn>
        </template>
        <v-btn
          fab
          dark
          x-small
          color="red"
          @click="exitRoom"
        >
          <v-icon>mdi-exit-to-app</v-icon>
        </v-btn>
        <v-btn
          fab
          dark
          x-small
          color="indigo"
          @click="toggleMic"
        >
          <v-icon v-if="microphoneOpen">mdi-microphone</v-icon>
          <v-icon v-else>mdi-microphone-off</v-icon>
        </v-btn>
      </v-speed-dial>
    </div>
  </v-main>
</template>

<script>
import Loading from '@/components/Loading'
import Panorama from '@/components/Panorama'
import LiveVoice from '@/components/LiveVoice'
import { mapState } from 'vuex'

export default {
  name: 'Live',
  components: {
    Loading, Panorama, LiveVoice
  },
  data: () => ({
    fab: false,
    loading: true,
    roomId: 0,
    sdkAppId: 1400468627,
    client: null,
    localStream: null
  }),
  computed: {
    ...mapState([
      'user'
    ]),
    ...mapState('moduleRoom', {
      roomInfo: state => state.info,
      microphoneOpen: state => state.microphoneOpen,
      roomNotExist: state => state.roomNotExist
    })
  },
  watch: {
    roomNotExist (newVal) {
      console.log('roomNotExist: ' + newVal)
      if (newVal) {
        this.$router.replace('/home')
      }
    }
  },
  created () {
    this.$store.commit('toggleNavigation', false)
    this.roomId = parseInt(this.$route.params.id)
    // 检查是否存在房间信息，没有则需要向服务器查询（直接打开链接的情况）
    if (!this.roomInfo) {
      this.$store.dispatch('moduleRoom/loadRoomInfo', { roomId: this.roomId })
    }
  },
  mounted () {
    setTimeout(() => {
      this.loading = false
    }, 2000)
  },
  methods: {
    exitRoom () {
      this.$refs.liveVoice.exitRoom()
      // 返回首页
      this.$router.replace('/home')
    },
    toggleMic () {
      this.$refs.liveVoice.toggleMic()
    }
  }
}
</script>

<style scoped>
  .live-panorama {
    position: absolute;
  }
  .live-avatar {
    position: relative;
    top: 10px;
    left: 10px;
  }
</style>
