export class MSocket {
  constructor (url) {
    this.url = url
    this.reconnecting = false
    this.initSocket()
  }

  initSocket () {
    try {
      console.log('m socket ' + this.url)
      this.ws = new WebSocket(this.url)
      this.ws.onopen = () => {
        console.log('建立连接成功!')
        this.heartCheck.start(this.ws)
        this.onopen()
      }
      this.ws.onmessage = (e) => {
        const data = JSON.parse(e.data)
        if (data.action === MSocket.SocketActions.HEART_CHECK) {
          this.heartCheck.start(this.ws)
        } else {
          this.onmessage(data)
        }
      }
      this.ws.onerror = (e) => {
        console.error(e)
        this.reconnect('onError')
        this.onerror(e)
      }
      this.ws.onclose = (e) => {
        console.error(e)
        this.reconnect('onClose')
        this.onclose(e)
      }
    } catch (e) {
      console.error(e)
    }
  }

  onopen = () => {}
  onmessage = (e) => { console.log(e) }
  onerror = (e) => { console.log(e) }
  onclose = (e) => { console.log(e) }
  send = (data) => { this.ws.send(data) }
  onstatechange = (online) => { console.log(online) }
  close = () => { this.ws.close() }
  // heart check
  heartCheck = {
    timeout: 10000,
    timeoutObj: null,
    serverTimeoutObj: null,
    reset: function () {
      clearTimeout(this.timeoutObj)
      clearTimeout(this.serverTimeoutObj)
    },
    start: function (ws) {
      this.reset()
      this.timeoutObj = setTimeout(() => {
        ws.send(JSON.stringify({ action: MSocket.SocketActions.HEART_CHECK }))
        this.serverTimeoutObj = setTimeout(() => {
          ws.close()
        }, this.timeout)
      }, this.timeout)
    }
  }

  reconnect (name) {
    if (this.reconnecting) {
      console.log(`${name} 已存在重连，被拒绝`)
      return
    }
    console.log(`${name} 开始重连`)
    this.reconnecting = true
    setTimeout(() => {
      this.initSocket()
      this.reconnecting = false
    }, 2000)
  }

  static SocketActions = {
    HEART_CHECK: 'HEART_CHECK',
    SYNC_VIEW: 'SYNC_VIEW'
  }
}
