<template>
  <div v-show="false">
    <div id="remote_stream"></div>
    <div id="local_stream"></div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getSig } from '@/api/room'
import TRTC from 'trtc-js-sdk'

export default {
  name: 'LiveVoice',
  data: () => ({
    sdkAppId: 1400468627,
    client: null,
    localStream: null,
    initDone: false
  }),
  props: {
    roomId: Number
  },
  computed: {
    ...mapState([
      'user'
    ]),
    ...mapState('moduleRoom', [
      'microphoneOpen'
    ])
  },
  watch: {
    roomId (newVal, oldVal) {
      console.log('=====================================')
      console.log(newVal, oldVal)
      if (!this.initDone && newVal !== -1) {
        this.initLive()
      }
    }
  },
  mounted () {
    if (this.roomId !== -1) {
      this.initLive()
      this.initDone = true
    }
  },
  beforeDestroy () {
    this.exitRoom()
  },
  methods: {
    initLive () {
      // 初始化，进入房间
      getSig(this.user.id).then(response => {
        if (response.data && response.data.code === 200) {
          this.enterRoom(response.data.data)
        } else {
          alert('获取签名失败')
        }
      })
    },
    enterRoom (userSig) {
      this.client = TRTC.createClient({
        mode: 'rtc',
        sdkAppId: this.sdkAppId,
        userId: this.user.id,
        userSig: userSig
      })
      // 注册事件
      this.client.on('stream-added', event => {
        const remoteStream = event.stream
        console.log('远端流增加: ' + remoteStream.getId())
        // 订阅远端流
        this.client.subscribe(remoteStream)
      })
      this.client.on('stream-subscribed', event => {
        const remoteStream = event.stream
        console.log('订阅远端流成功： ' + remoteStream.getId())
        const replay = () => {
          console.log('====================replay called===================')
          remoteStream.stop()
          remoteStream.play('remote_stream', { muted: false })
          // 移除用户鼠标事件监听
          document.removeEventListener('mousedown', replay)
          // 移除用户触屏事件监听
          document.removeEventListener('touchstart', replay)
        }
        document.addEventListener('mousedown', replay)
        document.addEventListener('touchstart', replay)
        // 播放远端流
        remoteStream.play('remote_stream', { muted: true })
        remoteStream.on('error', error => {
          const errorCode = error.getCode()
          if (errorCode === 0x4043) {
            document.addEventListener('mousedown', replay)
            document.addEventListener('touchstart', replay)
          }
        })
      })
      this.$store.commit('moduleRoom/toggleMicrophone', { isOpen: false })
      this.client.join({ roomId: this.roomId })
        .catch(err => {
          console.error('进房失败：' + err)
        })
        .then(() => {
          console.log('进房成功')
          this.loading = false
          // this.openMic()
        })
    },
    exitRoom () {
      if (!this.client) { return }
      this.closeMic()
      this.client.unpublish(this.localStream)
        .then(() => {
          console.log('取消发布本地流成功：：：exitRoom')
          this.client.leave()
            .then(() => {
              console.log('退房成功')
            })
            .catch(err => {
              console.error('退房失败：' + err)
            })
        })
    },
    openMic () {
      this.localStream = TRTC.createStream({
        userId: this.user.id,
        audio: true,
        video: false
      })
      this.localStream
        .initialize()
        .catch(err => {
          console.error('初始化本地流失败：' + err)
        })
        .then(() => {
          console.log('初始化本地流成功')
          this.publishStream()
        })
    },
    closeMic () {
      this.unPublishStream()
      if (this.localStream) {
        this.localStream.close()
      }
      this.$store.commit('moduleRoom/toggleMicrophone', { isOpen: false })
    },
    toggleMic () {
      if (this.microphoneOpen) {
        console.log('关闭麦克风')
        this.closeMic()
      } else {
        console.log('开启麦克风')
        this.openMic()
      }
    },
    publishStream () {
      this.client
        .publish(this.localStream)
        .catch(err => {
          console.error('本地流发布失败: ' + err)
        })
        .then(() => {
          console.log('本地流发布成功')
          this.$store.commit('moduleRoom/toggleMicrophone', { isOpen: true })
        })
    },
    unPublishStream () {
      // 未开启麦克风则不取消发布
      if (!this.microphoneOpen) { return }
      this.client.unpublish(this.localStream)
        .then(() => {
          console.log('取消发布本地流成功')
        })
    }
  }
}
</script>

<style scoped>

</style>
