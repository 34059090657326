<template>
  <div id="mba_panorama"></div>
</template>

<script>
import { MSocket } from '@/plugins/socket'
import { mapState } from 'vuex'

export default {
  name: 'Panorama',
  props: {
    panoHooks: Object,
    roomId: Number,
    xml: String
  },
  data: () => ({
    krpano: null,
    socket: null,
    wsUrl: 'wss://live.bigpixel.cn/api/ws',
    syncData: {
      ath: 0.1,
      atv: 0.1,
      fov: 10
    }
  }),
  computed: {
    ...mapState([
      'user'
    ])
  },
  watch: {
    xml (newVal) {
      if (newVal && this.krpano === null) {
        this.initPanorama()
      }
    }
  },
  mounted () {
    if (this.xml) {
      this.initPanorama()
    }
  },
  methods: {
    initPanorama () {
      this.wsUrl = `${this.wsUrl}/${this.roomId}?name=${this.user.id}`
      console.log(this.wsUrl)
      console.log('==========开始初始化krpano===========\n' + this.xml)
      window.embedpano({
        xml: this.xml,
        target: 'mba_panorama',
        html5: 'only',
        mobilescale: 1.0,
        onready: this.panoramaReady
      })
    },
    panoramaReady (obj) {
      this.krpano = obj.get('global')
      this.krpano.hooks = this.panoHooks
      // 连接socket
      this.initSocket()
    },
    initSocket () {
      this.socket = new MSocket(this.wsUrl)
      this.socket.onmessage = this.onmessage
    },
    onmessage (message) {
      switch (message.action) {
        case MSocket.SocketActions.SYNC_VIEW: {
          this.syncView(message.data)
          break
        }
      }
    },
    syncView (data) {
      this.krpano.call(`sync_view(${data.ath}, ${data.atv}, ${data.fov})`)
    }
  }
}
</script>

<style scoped>
  #mba_panorama {
    width: 100%;
    height: 100%;
  }
</style>
